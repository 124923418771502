// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Country } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useCountriesStore = defineStore('countries', () => {
  // fetch repositories
  const { $countries } = useNuxtApp()

  // state
  const countries = ref<Country[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const _countries = await $countries.index<Country[]>(config)
    countries.value = _countries as Country[] || []
    return countries
  }

  return {
    index,
    countries
  }
})
