// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { State } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useStateStore = defineStore('states', () => {
  // fetch repositories
  const { $states } = useNuxtApp()

  // state
  const states = ref<State[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const _states =
      await $states.index<State[]>(config)
    states.value = _states
    return states
  }

  return {
    index,
    states
  }
})
